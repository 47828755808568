.searchpage {
  display: flex;
  flex-direction: column;
  padding: 32px 70px;
  max-width: 2000px;
  width: calc(100% - 140px);
  margin: 0 auto;

  .account-history-container { 
    margin-top: 32px;



    .account-history-table {
      thead {
        tr {
          border-right: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          background: #f5f5f5;
          th {
            padding: 10px 24px;
            font-size: 1rem;
            font-weight: 700;
            color: #112339;
            font-size: 14px;
          }
        }
      }

      tbody {
          td {
            padding: 8px 24px;

            border-right: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            
    
            &.no-padding {
              padding: 0;
            }  
          }
      }
    }
  }

  @include respond(phone) {
    padding: 20px;
    width: calc(100% - 40px);
  }

  .alert-msg {
    background-color: #fef2e6;
    border: none;
    box-shadow: none;
    padding: 18px 24px;
    border-radius: 4px;
    color: #344054;
  }

  .search {
    margin-top: 24px;

    > h3 {
      font-weight: $bold;
      font-size: 1.25rem;
    }
  }

  .create-account-btn {
    margin-top: 24px;
    align-self: center;
  }

  .empty-list {
    align-self: center;
    margin-top: 24px;
  }
  
  .search-results {
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    .empty-list {
      align-self: center;
      margin-top: 24px;
    }

    .btns-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;

      button {
        min-width: 220px;
      }
      button.red-btn {
        min-width: unset;
      }
    }
  }
}

.confirm-details-modal {
  color: #344054;

  .primary-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .lds-spinner-container {
      margin-left: 0;

      .lds-spinner div:after {
        background-color: #fff;
      }
    }
  }
}
