@import "../variables";
.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-light-4;
    padding: 24px;
    border: 1px solid #D0021B;
    border-radius: 4px;
    font-size: 15px;
    color: $black-light;
    text-align: initial;
    box-shadow: 1px 2px 5px 0px #00000040;
    width: calc(100% - 48px);
    position: relative;
    &.icon {
        display: flex;
    }
    svg  {
        margin-right: 12px;
    }
    p {
        font-weight: 400;
    }
    &.error {
        margin-bottom: 24px;
        background: #D0021B;
        color: #fff;

        p {
            font-weight: 300 !important;
            margin-right: 12px;
            a {
                display: inline;
                text-decoration: underline;
                margin: 0;
            }
        }
        + .textbox-form {
            margin-top: 0;
        }

        > svg {
            min-width: 20px;
        }
    }
    &.center {
        align-items: center;
        svg {
            display: block;
        }
    }

    .close-icon {
        position: absolute;
        top: 10px;
        right: 0;
        padding-left: 12px;

        > svg  {
            width: 18px;
            height: 18px;
        }
    }

    .info {
       background: #D9EDF7;
    }
    button {
        &.link-btn {
            width: 100%;
            span {
                font-weight: 600;
            }
        }
    }
    a {
        color: $primary-blue;
        font-weight: 700;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
    }
    .center {
        text-align: center;
    }
}
